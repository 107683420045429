<template>
  <div class="adviceBox">
    <div class="adviceBox1">
      <el-tabs style="height:100%" v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="入库出库" name="1">
          <el-tabs style="height:calc(100% - 48px)" v-model="activeName1" class="demo-tabs" @tab-click="handleClick1">
            <el-tab-pane label="入库记录" name="1">
              <div v-if="!RKMain">
                <div class="display_flex top">
                  <div class="search-top title-top">
                    入库记录/入库
                  </div>
                  <div class="item">
                    <el-button @click="RKBackClick" type="info">
                      返回
                    </el-button>
                  </div>
                </div>
                <div class="tables display_flex">
                  <div class="tables-left">
                    <el-form :inline="true" :model="formData" class="demo-form-inline form-two">
                      <el-form-item>
                        <el-select clearable @change="type" placeholder="选择种类" v-model="formData.dict_type">
                          <el-option key="" label="全部" value=""></el-option>
                          <el-option v-for="item in dictList[160000000]" :key="item.id" :label="item.name"
                            :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-input class="w-208" @input="search" v-model="formData.searchs" @keydown.enter="search"
                          clearable placeholder="名称/拼音">
                          <template #suffix>
                            <div @click="search" class="searchcss">
                              <i class="fa fa-search"></i>
                            </div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                    <div class="tableSet">
                      <el-table class="sss" v-loading="dataListLoading" :data="tableData" size="small" border max-height="40vh"
                        style="width: 100%">
                        <el-table-column fixed width="150" prop="name" label="药品名称" show-overflow-tooltip />
                        <el-table-column width="120" prop="medi_spec" label="规格" show-overflow-tooltip />
                        <el-table-column width="80" align="center" prop="min_package_unit_name" label="进货单位"
                          show-overflow-tooltip />
                        <!-- <el-table-column width="80" align="center" prop="min_unit_name" label="零售单位"
                          show-overflow-tooltip /> -->
                        <el-table-column width="80" align="center" prop="min_package_num" label="换算系数"
                          show-overflow-tooltip>
                          <template #default="scope">
                            <span>{{ scope.row.min_package_num }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="售价" width="100">
                          <template #default="scope">
                            <span>{{ scope.row.sell_price }}元</span>
                          </template>
                        </el-table-column>
                        <el-table-column width="150" prop="company" show-overflow-tooltip label="厂家" />
                        <el-table-column fixed="right" label="" width="60">
                          <template #default="scope">
                            <div class="iconcliss" @click="addList(scope.row)">
                              <el-icon>
                                <arrow-right-bold />
                              </el-icon>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="pagination-box">
                        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
                          :background="true" layout="prev, pager, next" :total="pageData.totalSum"
                          @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
                      </div>
                    </div>
                  </div>
                  <div class="tables-right">
                    <el-table class="sss" v-loading="dataListLoading" :data="tableDataRight" stripe border max-height="40vh"
                      size="small">
                      <el-table-column width="200" prop="name" label="名称" fixed show-overflow-tooltip>
                        <template #default="scope">
                          <span>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="130" label="*批号" fixed show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-model="scope.row.batch_no" />
                        </template>
                      </el-table-column>
                      <el-table-column width="220" :label="'*入库数量'">
                        <template #default="scope">
                          <el-col :span="12">
                            <el-input type="number" v-model="scope.row.enter_num" :min="1">
                              <template #append>{{ scope.row.min_package_unit_name }}</template>
                            </el-input>
                          </el-col>
                          <el-col :span="12">
                            <el-input type="number" v-model="scope.row.enter_sub_num" :min="1">
                              <template #append>{{ scope.row.min_unit_name }}</template>
                            </el-input>
                          </el-col>
                        </template>
                      </el-table-column>
                      <el-table-column width="80" prop="name" label="换算系数" fixed show-overflow-tooltip>
                        <template #default="scope">
                          <span>{{ scope.row.min_package_num }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="150" label="*采购价">
                        <template #default="scope">
                          <el-input type="number" v-model="scope.row.buy_price" :min="0.001" :step="0.001">
                            <template #append>元 / {{ scope.row.min_package_unit_name }}</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column width="150" label="采购价总额">
                        <template #default="scope">
                          <span v-if="scope.row.enter_num > 0 && scope.row.buy_price > 0">
                            {{ (scope.row.buy_price * scope.row.enter_num + scope.row.buy_price *
                            scope.row.enter_sub_num
                            / scope.row.min_package_num).toFixed(2) }}</span>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column width="150" label="*零售价">
                        <template #default="scope">
                          <el-input type="number" v-model="scope.row.price" :min="0.001" :step="0.001">
                            <template #append>元 / {{ scope.row.min_package_unit_name }}</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column width="150" label="零售价总额">
                        <template #default="scope">
                          <span v-if="scope.row.enter_num > 0 && scope.row.price > 0">
                            {{ (scope.row.price * scope.row.enter_num + scope.row.price * scope.row.enter_sub_num
                            / scope.row.min_package_num).toFixed(2) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="100" label="进零差额">
                        <template #default="scope">
                          <span v-if="scope.row.enter_num > 0">{{ (scope.row.price * scope.row.enter_num -
                            scope.row.buy_price * scope.row.enter_num).toFixed(2) }}</span>
                        </template>
                      </el-table-column> -->
                      <el-table-column width="180" label="*到期时间">
                        <template #default="scope">
                          <el-date-picker type="date" v-model="scope.row.validity_end_time" placeholder="到期时间"
                            value-format="YYYY-MM-DD">
                          </el-date-picker>
                        </template>
                      </el-table-column>
                      <el-table-column fixed="right" label="操作" width="60">
                        <template #default="scope">
                          <el-popconfirm title="是否确定删除?" @confirm="rigthdel(scope.row, scope.$index)">
                            <template #reference>
                              <el-button type="text" size="small">
                                <span class="txfonts del">删除</span>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="saveRKSet">
                      <el-form :inline="true" :model="formDataRK" class="demo-form-inline">
                        <el-form-item label="入库时间：">
                          <el-date-picker v-model="formDataRK.enter_time" type="datetime" :clearable="false"
                            value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择入库日期">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="供货单位：">
                          <el-input v-model="formDataRK.supplier" placeholder="请输入供货单位">
                          </el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                          <el-input v-model="formDataRK.remark" clearable type="textarea" :rows="1" placeholder="请输入内容">
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <el-button style="float: right;margin-right: 20px;" type="primary"
                      @click="Confirmpuncture('1')">确定</el-button>
                  </div>
                </div>
              </div>
              <div v-if="RKMain">
                <div>
                  <div class="search-top">
                    <el-form :inline="true" :model="formDataRKHome" class="demo-form-inline">

                      <el-form-item>
                        <el-date-picker @change="formDataRKHomeSearch" v-model="formDataRKHome.time" type="daterange"
                          value-format="YYYY-MM-DD" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>

                      <el-form-item label="批号">
                        <el-input v-model="formDataRKHome.batch_no" @change="formSearchBatchNoChange" clearable />
                      </el-form-item>
                      <el-form-item label="名称">
                        <el-input v-model="formDataRKHome.name" @change="formSearchNameChange" clearable />
                      </el-form-item>
                      <el-form-item label="入库单号">
                        <el-input v-model="formDataRKHome.order_no" @change="formSearchOrderChange" clearable />
                      </el-form-item>
                      <!--
                      <el-button @click="RKlistClick" class="color-blue" type="primary">
                        <i class="fa fa-file-text-o"></i>入库列表
                      </el-button>
                      -->
                      <el-button class="color-yellow" type="primary" @click="RKClick">
                        <i class="fa fa-arrow-up"></i>入库
                      </el-button>
                      <!--
                      <el-button class="color-green" type="primary" @click="RKTJlistClick">
                        <i class="fa fa-file-text-o"></i>统计
                      </el-button>
                      -->
                      <el-button class="color-vice" type="primary" @click="exportTable">
                        <i class="fa fa-arrow-down"></i>导出
                      </el-button>
                      <el-button class="color-grey" @click="print()">
                        <i class="fa fa-print"></i>
                        打印
                      </el-button>
                    </el-form>
                  </div>

                </div>
                <div class="tablesOne">
                  <div>
                    <el-table class="sss" v-loading="dataListLoading" :data="tableDataRightHome" size="small" stripe border
                      max-height="44vh">
                      <el-table-column width="200" prop="name" label="名称" fixed show-overflow-tooltip>
                        <template #default="scope">
                          <span>{{ scope.row.drug.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="150" property="drug.medi_spec" fixed label="规格" show-overflow-tooltip />
                      <el-table-column width="150" prop="order_no" label="入库单号" />
                      <el-table-column width="120" prop="batch_no" label="批号" show-overflow-tooltip />
                      <el-table-column width="150" label="入库数量">
                        <template #default="scope">
                          <span>
                            <el-tag effect="dark">
                              {{ scope.row.enter_num }} {{ scope.row.drug.min_package_unit_name }}
                              <template v-if="scope.row.enter_sub_num">{{ scope.row.enter_sub_num }} {{
                                scope.row.drug.min_unit_name }}</template></el-tag>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column width="120" label="出库数量">
                        <template #default="scope">
                          <el-tag effect="dark">
                            {{ scope.row.total_out_num }} {{ scope.row.drug.min_package_unit_name }}
                            <template v-if="scope.row.total_out_sub_num">{{ scope.row.total_out_sub_num }} {{
                              scope.row.drug.min_unit_name }}</template></el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column label="库存数量" width="120" align="center">
                        <template #default="scope">
                          <el-tag effect="dark">
                            {{ scope.row.store_num }} {{ scope.row.drug.min_package_unit_name }}
                            <template v-if="scope.row.store_sub_num">{{ scope.row.store_sub_num }} {{
                              scope.row.drug.min_unit_name }}</template>
                          </el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column label="盘点数量" width="120" align="center">
                        <template #default="scope">
                          <el-tag effect="dark">
                            {{ scope.row.total_check_num }} {{ scope.row.drug.min_package_unit_name }}
                            <template v-if="scope.row.total_check_sub_num">{{ scope.row.total_check_sub_num }} {{
                              scope.row.drug.min_unit_name }}</template>
                          </el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column label="库存偏差" width="120" align="center">
                        <template #default="scope">
                          <el-tag effect="dark">
                            {{ scope.row.diff_num }} {{ scope.row.drug.min_package_unit_name }}
                            <template v-if="scope.row.diff_sub_num">{{ scope.row.diff_sub_num }} {{
                              scope.row.drug.min_unit_name }}</template>
                          </el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column label="换算系数" width="100">
                        <template #default="scope">
                          <span>{{ scope.row.drug.min_package_num }}</span>
                        </template>
                      </el-table-column>
                      <!-- 入库单价 -->
                      <el-table-column label="采购价" width="100">
                        <template #default="scope">
                          <span>{{ scope.row.buy_price }}元/{{scope.row.drug.min_package_unit_name}}</span>
                        </template>
                      </el-table-column>
                      <!-- 入库总额 -->
                      <el-table-column label="采购总价" width="100">
                        <template #default="scope">
                          <span>{{ scope.row.total_buy_price }}元</span>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column width="100" label="零售价">
                        <template #default="scope">
                          <span>{{ scope.row.price }}元/{{scope.row.drug.min_unit_name}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="100" label="零售总价">
                        <template #default="scope">
                          <span>{{ scope.row.total_sell_price }}元</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="100" label="进零差额">
                        <template #default="scope">
                          <span>{{ (scope.row.total_sell_price - scope.row.total_buy_price).toFixed(2) }}元</span>
                        </template>
                      </el-table-column> -->
                      <el-table-column width="110" prop="validity_end_time" align="center" label="到期时间"
                        show-overflow-tooltip />
                      <el-table-column width="110" prop="supplier" label="供应商" show-overflow-tooltip />
                      <el-table-column width="100" prop="remark" label="备注" align="center" show-overflow-tooltip />
                      <el-table-column width="180" prop="enter_time" align="center" label="入库时间" />
                      <el-table-column width="80" property="operator.name" label="入库人" align="center" />
                      <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template #default="scope">
                          <el-button type="text" size="small" @click="modParient(scope.row)">
                            <span class="txfonts modify">改</span>
                          </el-button>
                          <el-button type="text" size="small" @click="stockOut(scope.row)">
                            <span class="txfonts modify">出</span>
                          </el-button>
                          <el-button type="text" size="small" @click="pdSet(scope.row)">
                            <span class="txfonts modify">盘</span>
                          </el-button>
                          <el-popconfirm title="是否确认撤销吗 ?" @confirm="stockDelete(scope.row)">
                            <template #reference>
                              <el-button type="text" size="small" style="color:red">
                                撤
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="pagination-box">
                      <el-pagination v-model:currentPage="pageDataHomeRight.currentPage"
                        v-model:page-size="pageDataHomeRight.pageSize" :page-sizes="[10, 20, 50, 100]"
                        :background="true" layout="total, sizes, prev, pager, next, jumper"
                        :total="pageDataHomeRight.totalSum" @size-change="handleSizeChangeHomeRight"
                        @current-change="handleCurrentChangeHomeRight"></el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="出库记录" name="2">
              <!--
              <my-THJL ref="THJLRef"></my-THJL>
              -->
              <my-CKJL ref="CKJLRef"></my-CKJL>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!--
        <el-tab-pane label="出库管理" name="2" style="height:calc(100% - 48px)">
          <el-tabs v-model="activeName2" class="demo-tabs" @tab-click="handleClick2">
            <el-tab-pane label="出库记录" name="1">
              <my-CKJL ref="CKJLRef"></my-CKJL>
            </el-tab-pane>
            <el-tab-pane label="退库记录" name="2">
              <my-TKJL ref="TKJLRef"></my-TKJL>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        -->
        <!-- <el-tab-pane label="库存查询" name="3">
          <el-tabs v-model="activeName3" class="demo-tabs" @tab-click="handleClick3">
            <el-tab-pane label="药品查询" name="1">
              <my-YPCX ref="YPCXRef"></my-YPCX>
            </el-tab-pane> -->
        <!-- <el-tab-pane label="患者使用" name="2">
              <my-HZSY ref="HZSYRef"></my-HZSY>
            </el-tab-pane> -->
        <!-- </el-tabs>
        </el-tab-pane> -->
        <el-tab-pane label="库存盘点" name="4">
          <my-KCPD ref="KCPDRef"></my-KCPD>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- tabs标签页 -->
    <!-- 修改弹窗 -->
    <div>
      <BaseDialog title="修改" :isshow="puncture" @handleShow="handleShows" width="700px" height="auto">
        <!-- 内容-->
        <el-descriptions :column="3" size="small" border style="margin-top:10px;">
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>
        <div class="addform" style="margin-top:10px;">
          <el-form :model="ruleFormEdit" ref="ruleFormEditRef" label-width="120px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="批号">
                  <el-input v-model="ruleFormEdit.batch_no" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="入库数量">
                  <el-col :span="6">
                    换算系数 {{ drug.min_package_num }}
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormEdit.enter_num" placeholder="">
                      <template #append>{{ drug.min_package_unit_name }}</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormEdit.enter_sub_num" placeholder="">
                      <template #append>{{ drug.min_unit_name }}</template>
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="进货单位">
                  <el-input disabled v-model="drug.min_package_unit_name" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="进价">
                  <el-input v-model="ruleFormEdit.buy_price" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="采购总价">
                  <template #default="scope">
                    <span v-if="drug.min_package_num">{{ (ruleFormEdit.buy_price * ruleFormEdit.enter_num +
                      ruleFormEdit.buy_price *
                      ruleFormEdit.enter_sub_num
                      / drug.min_package_num).toFixed(2) }}</span>
                    <span v-else>0.00</span>
                  </template>

                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="8">
                <el-form-item label="零售单位">
                  <el-input disabled v-model="drug.min_unit_name" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="零售价">
                  <el-input v-model="ruleFormEdit.price" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="零售总价">
                  {{
                  ((Number(ruleFormEdit.enter_num)*drug.min_package_num+Number(ruleFormEdit.enter_sub_num))*ruleFormEdit.price).toFixed(2)
                  }}
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="24">
                <el-form-item label="有效期">
                  <el-date-picker v-model="ruleFormEdit.validity_end_time" value-format="YYYY-MM-DD" type="date"
                    placeholder="有效期至" style="width: 100%" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="供应商">
                  <el-input v-model="ruleFormEdit.supplier" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input v-model="ruleFormEdit.remark" type="textarea" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-divider />
        <div style="text-align: right">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="handleEdit()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <!-- 出库弹窗 -->
    <div>
      <BaseDialog title="出库" :isshow="stockOutDialog" @handleShow="(val)=>{stockOutDialog=val}" width="700px"
        height="auto">
        <el-descriptions :column="3" size="small" border style="margin-top:10px;">
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>
        <div class="addform" style="margin-top:10px;">
          <el-form :model="ruleFormOut" ref="ruleFormOutRef" label-width="120px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="批号">
                  <el-input disabled v-model="ruleFormOut.batch_no" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="出库数量">
                  <el-col :span="6">
                    换算系数 {{ drug.min_package_num }}
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormOut.out_num" placeholder="">
                      <template #append>{{ drug.min_package_unit_name }}</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormOut.out_sub_num" clearable placeholder="">
                      <template #append>{{ drug.min_unit_name }}</template>
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="出库单位">
                  <el-input disabled v-model="drug.min_package_unit_name" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价">
                  <el-input v-model="ruleFormOut.out_price" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出库总价">
                  <template #default="scope">
                    <span v-if="drug.min_package_num">{{ (ruleFormOut.out_price * ruleFormOut.out_num +
                      ruleFormOut.out_price *
                      ruleFormOut.out_sub_num
                      / drug.min_package_num).toFixed(2) }}</span>
                    <span v-else>0.00</span>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出库对象:">
                  <el-select clearable placeholder="选择出库对象" v-model="ruleFormOut.out_touid">
                    <el-option v-for="item in userInfo" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="出库类型:">
                  <el-select clearable placeholder="选择出库类型" v-model="ruleFormOut.dict_out_type">
                    <el-option v-for="item in dictList[205000000]" :key="item.id" :label="item.name"
                      :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出库时间：">
                  <el-date-picker v-model="ruleFormOut.out_time" type="datetime" :clearable="false"
                    value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择出库日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="text-align: right">
          <el-button @click="cancelOut">取消</el-button>
          <el-button type="primary" @click="handleOut()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <!-- 盘点弹窗 -->
    <div>
      <BaseDialog title="盘点" @handleShow="(val) => {pdSetDialog = val }" :isshow="pdSetDialog" width="700px"
        height="auto">
        <el-descriptions :column="3" size="small" border style="margin-top:10px;">
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>
        <div class="addform" style="margin-top:10px;">
          <el-form :model="ruleFormPd" ref="ruleFormPdRef" label-width="120px" :rules="rulesForm">
            <el-row>
              <el-col :span="24">
                <el-form-item label="批号">
                  <el-input disabled v-model="ruleFormPd.batch_no" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="盘点数量">
                  <el-col :span="6">
                    换算系数 {{ drug.min_package_num }}
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormPd.check_num" placeholder="">
                      <template #append>{{ drug.min_package_unit_name }}</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormPd.check_sub_num" placeholder="">
                      <template #append>{{ drug.min_unit_name }}</template>
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="盘点时间：" prop="check_date">
                  <el-date-picker v-model="ruleFormPd.check_date" type="date" :clearable="false"
                    value-format="YYYY-MM-DD" placeholder="请选择盘点日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注：">
                  <el-input type="textarea" v-model="ruleFormPd.remark" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="text-align: right">
          <el-button @click="cancelPd">取消</el-button>
          <el-button type="primary" @click="handlePd()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <!-- 入库列表弹窗 -->
    <my-RKLB ref="RKLBRef"></my-RKLB>
    <!-- 入库弹窗统计 -->
    <my-RKTJ ref="RKTJRef"></my-RKTJ>
    <printRK ref="printRKRef"></printRK>
  </div>
</template>

<script>
  import printRK from '../depmtDrug/printRK.vue'
  import { toRefs, reactive, onMounted, ref, getCurrentInstance, nextTick } from 'vue'
  import { Search, ArrowRightBold, ArrowLeftBold } from '@element-plus/icons-vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { gitDictList, TimeYear, downLoadXlsx, TimestampToTime } from '@/utils/tool'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import myTHJL from '../consumables/THJL.vue'
  import myTKJL from '../consumables/TKJL.vue'
  import myCKJL from '../consumables/CKJL.vue'
  import myKCPD from '../consumables/KCPD.vue'
  import myYPCX from '../consumables/YPCX.vue'
  import myHZSY from '../consumables/HZSY.vue'
  import myRKLB from '../consumables/RKLB.vue'
  import myRKTJ from '../consumables/RKTJ.vue'
  export default {
    components: {
      BaseDialog,
      myTHJL,
      myTKJL,
      myCKJL,
      myKCPD,
      myYPCX,
      myHZSY,
      myRKLB,
      myRKTJ,
      ArrowRightBold,
      printRK
    },
    setup() {
      let { proxy } = getCurrentInstance()
      const state = reactive({
        ruleForm: {
          tag: [],
          is_enable: true,
        },
        tags: [],
        formData: {
          time: '',
        },
        rulesForm: {
          check_date: [{ required: true, trigger: 'change', message: '请选择' },],
        },
        formDataRK: {},//入库保存
        formDataRKHome: {},//入库首页
        formInline: {
          status: ''
        },
        ruleFormEdit: {},//首页退货
        ruleFormOut: {
        }, // 出库
        ruleFormAlert: {
          name: ''
        },//弹窗入库列表
        dictList: {},
        tableData: [],
        listop: [],
        lists: [],
        doclist: [],
        doclists: [],
        puncture: false,
        stockOutDialog: false,
        pdSetDialog: false,
        saveLoading: false,
        ruleFormRef: null,
        isRecord: false,
        dict_type: '',
        activeName: '1',
        activeName1: '1',
        activeName2: '1',
        activeName3: '1',
        tableHei: '',
        disabled: false,
        dict_advice_type: '',
        dict_catalog_type: '',
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        pageDataHomeLeft: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        pageDataHomeRight: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        conmons: [],
        idRow: '',
        RKMain: true,
        tableDataRight: [],//右侧列表数据
        tableDataRightHome: [],//首页入库
        tableDataHomeLeft: [],
        RKLBRef: null,
        RKTJRef: null,
        THJLRef: null,
        YPCXRef: null,
        HZSYRef: null,
        TKJLRef: null,
        CKJLRef: null,
        KCPDRef: null,
        userInfo: [],
        drug: {},
        ruleFormPd: {}
      })
      onMounted(() => {
        initData()
        tobody()
        getUserList()
      })
      const getUserList = async () => {
        let res = await service.post('/api/user/get_all_nurse')
        if (res.code === 0) {
          state.userInfo = res.data.list
        }
      }
      // tabs标签页点击事件
      const handleClick = (e) => {
        state.activeName = JSON.parse(JSON.stringify(e.props.name))
        if (state.activeName == '1') {
          tobody()
        } else if (state.activeName == '2') {
          nextTick(() => {
            state.CKJLRef.tobody()
          })
        } else if (state.activeName == '3') {
          nextTick(() => {
            state.YPCXRef.tobody()
          })
        } else if (state.activeName == '4') {
          nextTick(() => {
            state.KCPDRef.tobody()
          })
        }
      }
      //入库管理tab
      const handleClick1 = (e) => {
        state.activeName1 = JSON.parse(JSON.stringify(e.props.name))
        if (state.activeName1 == '1') {
          tobody()
        } else {
          nextTick(() => {
            state.THJLRef.tobody()
          })
        }
      }
      //出库管理tab
      const handleClick2 = (e) => {
        state.activeName2 = JSON.parse(JSON.stringify(e.props.name))
        if (state.activeName2 == '2') {
          nextTick(() => {
            state.TKJLRef.tobody()
          })
        } else {
          nextTick(() => {
            state.CKJLRef.tobody()
          })
        }
        // tobody()
      }
      //库存查询tab
      const handleClick3 = (e) => {
        state.activeName3 = JSON.parse(JSON.stringify(e.props.name))
        if (state.activeName3 == '1') {
          nextTick(() => {
            state.YPCXRef.tobody()
          })
        } else {
          nextTick(() => {
            state.HZSYRef.tobody()
          })
        }
        // tobody()
      }
      // 当条数改变时
      const handleSizeChangeHomeLeft = (e) => {
        state.pageDataHomeLeft.pageSize = e
        //获取入库列表详情药品数据
        tobody()
      }
      // 当页数改变时
      const handleCurrentChangeHomeLeft = (e) => {
        state.pageDataHomeLeft.currentPage = e
        //获取入库列表详情药品数据
        tobody()
      }
      // 当条数改变时
      const handleSizeChangeHomeRight = (e) => {
        state.pageDataHomeRight.pageSize = e
        //获取入库列表详情药品数据
        tobody()
      }
      // 当页数改变时
      const handleCurrentChangeHomeRight = (e) => {
        state.pageDataHomeRight.currentPage = e
        //获取入库列表详情药品数据
        tobody()
      }
      // 改变联机选择
      const props = {
        label: 'name',
        value: 'code',
        children: 'childrenItems',
      }
      // 退货弹窗关闭
      const handleShows = (val) => {
        console.log(val)
        if (!val) {
          state.ruleForm = {
            is_enable: true,
          }
          state.disabled = false

          state.dictList[332900000].forEach((item) => {
            item.active = false
          })
        }
        state.puncture = val
      }

      // 获取字典
      const initData = async () => {
        state.dictList = await gitDictList([
          '332900000',
          '210000000',
          '103000000',
          '164000000',
          '116000000',
          '176000000',
          '231000000',
          '157000000',
          '158000000',
          '159000000',
          '339600000',
          '164000000',
          '113000000',
          '161000000',
          '100000000',
          '340200000',
          '339600000',
          '157000000',
          '205000000',
        ])
        state.dictList[332900000].forEach((item) => {
          item.active = false
        })
        state.listop = state.dictList[332900000]
        state.lists = JSON.parse(JSON.stringify(state.dictList[332900000]))
        // 治疗项
        state.dictList[340200000].forEach((item) => {
          item.active = false
        })
        state.doclist = state.dictList[340200000]
        state.doclists = JSON.parse(JSON.stringify(state.dictList[340200000]))
      }
      // 获取列表
      const tobody = async () => {
        let data = {}
        if (state.formDataRKHome.time) {
          data = {
            current: state.pageDataHomeRight.currentPage,
            size: state.pageDataHomeRight.pageSize,
            start_time: state.formDataRKHome.time[0],
            end_time: state.formDataRKHome.time[1],
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            dict_catalog_type: "164000000"
          }
        } else {
          data = {
            current: state.pageDataHomeRight.currentPage,
            size: state.pageDataHomeRight.pageSize,
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            dict_catalog_type: "164000000"
          }
        }
        let res = await service.post('/api/store_enter_record/get_enter_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableDataRightHome = res.data.records
            state.pageDataHomeRight.totalSum = res.data.total
          } else {
            state.pageDataHomeRight.totalSum = 0
            state.tableDataRightHome = []
          }
        }
      }

      // 编辑
      const handleEdit = async () => {
        let url = '/api/store_enter_record/edit_record'
        await service.post(url, state.ruleFormEdit)
        state.puncture = false
        state.ruleFormEdit = {}
        tobody()
      }

      // 出库
      const handleOut = async () => {
        let url = '/api/store_out_record/create_single_record'
        let data = {
          id: state.ruleFormOut.id == undefined ? '' : state.ruleFormOut.id,
          out_num: state.ruleFormOut.out_num == undefined ? '' : state.ruleFormOut.out_num,
          out_sub_num: state.ruleFormOut.out_sub_num == undefined ? '' : state.ruleFormOut.out_sub_num,
          out_touid: state.ruleFormOut.out_touid == undefined ? '' : state.ruleFormOut.out_touid,
          dict_out_type: state.ruleFormOut.dict_out_type == undefined ? '' : state.ruleFormOut.dict_out_type,
          out_time: state.ruleFormOut.out_time == undefined ? '' : state.ruleFormOut.out_time,
          out_price: state.ruleFormOut.out_price == undefined ? '' : state.ruleFormOut.out_price
        }
        await service.post(url, data)
        state.stockOutDialog = false
        state.ruleFormOut = {}
        tobody()
        state.CKJLRef.tobody()
      }

      // 新增确定 退货确定
      const Confirmpuncture = async (code) => {
        let data = {}
        let url = ''
        let arr = []
        if (code == '1') {
          //入库 保存功能  即保存左侧点击之后需要添加的右侧表格数据
          state.tableDataRight.forEach(item => {
            let obj = {
              drug_id: item.id == undefined ? '' : item.id,
              enter_num: item.enter_num == undefined ? '' : item.enter_num,
              enter_sub_num: item.enter_sub_num == undefined ? '' : item.enter_sub_num,
              batch_no: item.batch_no == undefined ? '' : item.batch_no,
              validity_end_time: item.validity_end_time == undefined ? '' : item.validity_end_time,
              price: item.price == undefined ? '' : item.price,
              buy_price: item.buy_price == undefined ? '' : item.buy_price,
            }
            arr.push(obj)
          })
          state.formDataRK.enter_drug = arr
          data = state.formDataRK,
            data.dict_catalog_type = '164000000'
          url = '/api/store_enter_record/create_record'
          if (state.formDataRK.enter_drug.some((val) => val.enter_num == '')) {
            ElMessage({
              message: '入库数量不能为空',
              type: 'error',
            })
            return;
          }
          if (state.formDataRK.enter_drug.some((val) => val.batch_no == '')) {
            ElMessage({
              message: '批号不能为空',
              type: 'error',
            })
            return;
          }
          if (state.formDataRK.enter_drug.some((val) => val.buy_price == '')) {
            ElMessage({
              message: '采购单价不能为空',
              type: 'error',
            })
            return;
          }
          if (state.formDataRK.enter_drug.some((val) => val.validity_end_time == '')) {
            ElMessage({
              message: '到期时间不能为空',
              type: 'error',
            })
            return;
          }
        //   if (state.formDataRK.enter_drug.some((val) => val.price == '')) {
        //     ElMessage({
        //       message: '零售单价不能为空',
        //       type: 'error',
        //     })
        //     return;
        //   }
        }
         else {
          //首页退货
          data = state.ruleFormEdit
          data.dict_catalog_type = '164000000'
          url = '/api/stockReturn/save'
        }
        let res = await service.post(url, data)
        if (res.code == 0) {
          if (code == '1') {
            ElMessage({
              message: '入库成功',
              type: 'success',
            })
            state.formDataRK.enter_drug = []
            state.tableDataRight = []
            state.formDataRK.supplier = ''
            state.formDataRK.remark = ''
          } else {
            ElMessage({
              message: '退货成功',
              type: 'success',
            })
            state.puncture = false
            state.ruleFormEdit = {}
            detail(state.idRow.id)
          }
        }
      }
      // 退货
      const stockReturn = async (row) => {
        let data = {
          enter_id: row.id,
        }
        let res = await service.post('/api/store_enter_record/return_record', data)
        if (res.code == 0) {
          ElMessage({
            message: '退货成功',
            type: 'success',
          })
          tobody()
        }
      }
      // 删除
      const stockDelete = async (row) => {
        let data = {
          id: row.id
        }
        let res = await service.post('/api/store_enter_record/delete_record', data)
        if (res.code == 0) {
          ElMessage({
            message: '撤销成功',
            type: 'success',
          })
          tobody()
        }
      }
      // 首页表格退货
      const modParient = async (row) => {
        state.ruleFormEdit = row
        state.drug = row.drug
        state.puncture = true
      }
      // 出库
      const stockOut = (row) => {
        state.ruleFormOut = row
        state.ruleFormOut.out_sub_num = 0
        // state.ruleFormOut.out_num =0
        // state.ruleFormOut.out_price =0
        state.drug = row.drug
        state.stockOutDialog = true
      }
      // 弹窗取消
      const cancel = () => {
        state.puncture = false
      }
      const cancelOut = () => {
        state.stockOutDialog = false
        state.ruleFormOut = {}
      }
      //入库首页时间切换
      const formDataRKHomeSearch = (val) => {
        state.formDataRKHome.time = val
        state.pageDataHomeRight.currentPage = 1
        tobody()
      }
      //修改批号
      const formSearchBatchNoChange = (val) => {
        state.formDataRKHome.batch_no = val
        state.pageDataHomeRight.currentPage = 1
        tobody()
      }
      // 修改名称
      const formSearchNameChange = (val) => {
        state.formDataRKHome.name = val
        state.pageDataHomeRight.currentPage = 1
        tobody()
      }
      // 修改入库单号
      const formSearchOrderChange = (val) => {
        state.formDataRKHome.order_no = val
        state.pageDataHomeRight.currentPage = 1
        tobody()
      }
      // 左侧表格点击
      const Rowclick = (row) => {
        state.idRow = row
        detail(row.id)
      }
      //添加颜色
      const rowStyle = (row) => {
        if (state.idRow.id === row.row.id) {
          return { 'background-color': '#ccc', cursor: 'pointer', 'color': '#333' };
        }
        return { cursor: 'pointer' };
      }
      // 右侧表格列表
      const detail = async (id) => {
        let data = {}
        if (state.formDataRKHome.time) {
          data = {
            current: state.pageDataHomeRight.currentPage,
            size: state.pageDataHomeRight.pageSize,
            start_time: state.formDataRKHome.time[0],
            end_time: state.formDataRKHome.time[1],
            dict_catalog_type: "164000000"
          }
        } else {
          data = {
            current: state.pageDataHomeRight.currentPage,
            size: state.pageDataHomeRight.pageSize,
            dict_catalog_type: "164000000"
          }
        }
        let res = await service.post('/api/store_enter_record/get_enter_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableDataRightHome = res.data.records
            state.pageDataHomeRight.totalSum = res.data.total
          } else {
            state.pageDataHomeRight.totalSum = 0
            state.tableDataRightHome = []
          }
        }
      }

      //入库列表点击事件
      const RKlistClick = () => {
        nextTick(() => {
          state.RKLBRef.initData()
          state.RKLBRef.tobodyAlert()
        })
      }
      //入库列表点击之后获取药品信息
      // 获取列表
      const RKLBCGetInfo = async () => {
        state.dict_catalog_type = '164000000'
        let data = {
          current: state.pageData.currentPage,
          size: state.pageData.pageSize,
          search_code: state.formData.searchs,
          is_enable: state.formData.status,
          dict_type: state.formData.dict_type,
          dict_advice_type: state.dict_advice_type,
          dict_catalog_type: state.dict_catalog_type,
          tag: state.tags,
        }
        let res = await service.post('/api/drug/list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.tableData.forEach((item) => {
              item.drug_id = item.id
            })
            state.pageData.totalSum = res.data.total
          } else {
            state.pageData.totalSum = 0
            state.tableData = []
          }
        }
      }
      // 当条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        //获取入库列表详情药品数据
        RKLBCGetInfo()
      }
      // 当页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        //获取入库列表详情药品数据
        RKLBCGetInfo()
      }
      // 选择种类
      const type = () => {
        // 入库列表点击之后获取药品信息
        RKLBCGetInfo()
      }
      // 搜索
      const search = () => {
        // 入库列表点击之后获取药品信息
        RKLBCGetInfo()
      }
      //入库列表获取详情标签选择
      const tagBut = (row) => {
        console.log(row)
        row.active = !row.active
        let tabList = []
        const item = state.listop
        for (let i = 0; i < item.length; i++) {
          if (item[i].active) {
            tabList.push(item[i].code)
          }
        }
        state.tags = tabList
        RKLBCGetInfo()
      }
      //入库统计点击
      const RKTJlistClick = () => {
        nextTick(() => {
          state.RKTJRef.tobodyAlert()
        })
      }
      // 导出
      const exportTable = (row) => {
        let data = {}
        if (state.formDataRKHome.time) {
          data = {
            start_time: state.formDataRKHome.time[0],
            end_time: state.formDataRKHome.time[1],
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            dict_catalog_type: "164000000"
          }
        } else {
          data = {
            start_time: '',
            end_time: '',
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            dict_catalog_type: "164000000"
          }
        }
        let time = TimestampToTime()
        let title = `科室耗材入库${time}.xlsx`
        downLoadXlsx('/api/store_enter_record/export_enter_list', data, title)
      }
      //点击入库
      const RKClick = () => {
        state.RKMain = false
        //获取当前登陆人信息
        let userData = JSON.parse(localStorage.getItem('ms_userData'))
        state.formDataRK.user = userData.name
        state.formDataRK.operator_id = userData.id
        let time = timestampToTime()
        state.formDataRK.enter_time = time
        console.log(time)
        RKLBCGetInfo()
      }
      const timestampToTime = () => {
        let date = new Date()
        let year = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return year + M + D + h + m + s;
      }
      //点击入库返回事件
      const RKBackClick = () => {
        state.RKMain = true
        tobody()
      }
      //
      const selectchan = (val) => {
        console.log(val)
      }
      const addList = (row) => {
        let param = {
          enter_num: 1,
          batch_no: '',
          validity_end_time: '',
          id: row.id,
          name: row.name,
          medi_spec: row.medi_spec,
          price: 0,
          buy_price: 0,
          enter_num: 0,
          enter_sub_num: 0,
          company: row.company,
          country_code: row.country_code,
          min_package_num: row.min_package_num,
          min_package_unit_name: row.min_package_unit_name,
          min_unit_name: row.min_unit_name,
          dict_first_unit_name: row.dict_first_unit_name
        }
        state.tableDataRight.push(param)
        // if (state.tableDataRight.length == 0) {
        //   state.tableDataRight.push(param)
        // } else {
        //   if (!(state.tableDataRight.some((item) => item.id == param.id))) {
        //     state.tableDataRight.push(param);
        //   } else {
        //     return ElMessage.error('不能添加重复的药品！')
        //   }
        // }
      }
      // 删除
      const delParient = (item) => {
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {

            // let res = await service.post('api/complication/drugDelete', {
            //   drug_id: item.id,
            // })
            // if (res.code === 0) {
            //   ElMessage.success(res.msg)
            //   getDataList()
            // }
          })
          .catch(() => { })
      }
      // 右侧表格删除
      const rigthdel = async (row, index) => {
        console.log(row)
        row.enter_num = undefined
        row.batch_no = ''
        row.validity_end_time = ''
        row.country_code = ''
        row.dict_first_unit_name = ''
        state.tableDataRight.splice(index, 1)
        // let res = await service.post('/api/setup/projectDelete', data)
        // if (res.code === 0) {
        //   ElMessage({
        //     message: '删除成功',
        //     type: 'success',
        //   })
        //   rightbody(state.sid)
        // }
      }
      let handleprops = ref('')
      handleprops = {
        lazy: true,
        value: 'code',
        label: 'name',
        async lazyLoad(node, resolve) {
          const { value, level } = node
          let nodes = []
          let codes = ''
          if (level === 0) {
            codes = '339600000'
          } else {
            codes = value
          }
          let res = await gitDictList([codes])
          state.list = res[codes]
          nodes = res[codes]
          nodes.map(function (item) {
            item.leaf = level >= 2
          })
          resolve(nodes)
        },
      }
      const print = async () => {
        let data = {}
        if (state.formDataRKHome.time) {
          data = {
            current: 1,
            size: 10000,
            start_time: state.formDataRKHome.time[0],
            end_time: state.formDataRKHome.time[1],
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            enter_id: state.idRow.id,
            dict_catalog_type: "164000000"
          }
        } else {
          data = {
            current: 1,
            size: 10000,
            batch_no: state.formDataRKHome.batch_no,
            name: state.formDataRKHome.name,
            order_no: state.formDataRKHome.order_no,
            enter_id: state.idRow.id,
            dict_catalog_type: "164000000"
          }
        }
        let res = await service.post('/api/store_enter_record/get_enter_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            let data = {
              ...state.idRow,
              drug_list: res.data.records
            }
            proxy.$refs.printRKRef.initData(data)
            nextTick(() => {
              proxy.$refs.printRKRef.print()
            })
          } else {
            ElMessage.error('暂无打印数据')
          }
        }
      }
      // 盘点
      const handlePd = async () => {
        proxy.$refs.ruleFormPdRef.validate(async (volid) => {
          if (volid) {
            let url = '/api/stockCheck/save'
            let data = {
              record_id: state.ruleFormPd.id,
              check_sub_num: state.ruleFormPd.check_sub_num == undefined ? '' : state.ruleFormPd.check_sub_num,
              check_num: state.ruleFormPd.check_num == undefined ? '' : state.ruleFormPd.check_num,
              check_date: state.ruleFormPd.check_date == undefined ? '' : state.ruleFormPd.check_date,
              remark: state.ruleFormPd.remark == undefined ? '' : state.ruleFormPd.remark
            }
            await service.post(url, data)
            state.pdSetDialog = false
            state.ruleFormPd = {}
            tobody()
            state.KCPDRef.tobody()
          } else {
            return false
          }
        })

      }
      //盘点
      const pdSet = (row) => {
        state.ruleFormPd = row
        state.drug = row.drug
        state.pdSetDialog = true
      }
      const cancelPd = () => {
        state.pdSetDialog = false
        state.ruleFormPd = {}
      }
      return {
        ...toRefs(state),
        handleClick,
        handleClick1,
        handleClick2,
        handleClick3,
        search,
        initData,
        cancel,
        cancelOut,
        tobody,
        props,
        Confirmpuncture,
        handleEdit,
        handleOut,
        tagBut,
        handleCurrentChange,
        handleSizeChange,
        modParient,
        stockReturn,
        stockDelete,
        stockOut,
        delParient,
        handleShows,
        type,
        selectchan,
        handleprops,
        Rowclick,
        rowStyle,
        RKlistClick,
        RKTJlistClick,
        RKClick,
        RKBackClick,
        addList,
        RKLBCGetInfo,
        rigthdel,
        formDataRKHomeSearch,
        formSearchOrderChange,
        formSearchBatchNoChange,
        formSearchNameChange,
        handleSizeChangeHomeLeft,
        handleSizeChangeHomeRight,
        handleCurrentChangeHomeLeft,
        handleCurrentChangeHomeRight,
        exportTable,
        stockReturn,
        print,
        pdSet,
        cancelPd,
        handlePd
      }
    },
  }
</script>

<style scoped lang="scss">
  .demo-form-inline {
    display: flex;
    flex-wrap: wrap;
  }

  .el-table {
    &:deep(.el-date-editor.el-input) {
      width: 98%;
    }
  }

  .adviceBox {
    height: 100%;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }

  .demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .item {
    display: flex;
    flex-wrap: wrap;
  }

  .faicon {
    font-size: 19px;
    margin-right: 6px;
    cursor: pointer;
  }

  .title-top {
    align-items: center;
    display: flex;
  }

  .tabclis {
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    padding: 3px;

    .tabclis_right {
      display: flex;
      padding-top: 2px;
    }
  }

  .pagination-box {
    &:deep(.el-input__inner) {
      padding-right: 15px !important;
    }
  }

  .w-144 {
    margin-top: 20px;
  }

  .stop {
    background-color: #f4a939;
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .top {
    height: 35px;
    margin: 8px 0;
  }
</style>

<style scoped>
  .saveRKSet {
    margin-top: 10px;
  }

  .el-input-group__append .el-select>>>.el-input__inner {
    border: none !important;
  }

  .el-form.w-144 .el-select {
    width: 100px !important;
  }

  .tables-left {
    margin-right: 10px;
    width: 30%;
  }

  .tables-right {
    margin-right: 10px;
    width: 70%;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }

  .margin-set {
    padding: 10px;
    float: right;
  }

  .form-two {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .form-two .el-form-item {
    width: 48% !important;
  }

  .iconcliss {
    width: 32px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #303030;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    font-size: 18px;
  }
</style>